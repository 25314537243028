<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog group="dialChangeStatus" />
  </div>
</template>
<script>
import supportService from "../../services/support.service";

export default {
  name: "ChangeStatus",
  props: ["show", "newStatus", "showToast", "pats"],
  watch: {
    show() {
      if (this.show === true) {
        this.confirmChangeStatus();
      }
    },
  },
  methods: {
    confirmChangeStatus() {
      let title = `Alterar Assistência para ${this.newStatus}`;
      let message = `Tem a certeza que pretende alterar o estado das assistências?`;

      const patNames = this.pats.map(pat => `${pat.pat} - ${pat.fullName}`).join(", ");
      message = `Tem a certeza que pretende alterar o estado das assistências ${patNames} para ${this.newStatus}?`;

      this.$confirm.require({
        key: "dialogChangeStatus",
        header: title,
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        accept: () => {
          return this.changeStatus();
        },
        reject: () => {
          return this.$emit("cancel");
        },
      });
    },
    async changeStatus() {
      let success = false;
      let statusSucess = "";
      let newStatus = this.newStatus;
      for (let pat of this.pats) {
        let response = await supportService.changeStatus(parseInt(pat.pat), { status: newStatus });

        if (!response) {
          if (this.showToast) {
            this.$toast.add({
              severity: "error",
              summary: `Erro ao alterar o estado da assistência para ${newStatus}`,
              detail: "",
              life: 3000,
            });
          }
          return this.$emit("cancel", {
            status: "error",
            currentStatus: {},
          });
        }
        success = true;
        statusSucess = response.status;
      }
      if (success) {
        if (this.showToast) {
          this.$toast.add({
            severity: "success",
            summary: `Alterado o estado da assistência para ${statusSucess}`,
            detail: "",
            life: 3000,
          });
          this.$emit("changeStatusOk", {
            status: "ok",
            currentStatus: statusSucess,
          });
        }
      }
    },
  },
};
</script>
