<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Alterar Técnico Responsável pela Assistência'"
      :modal="true"
      :closable="false"
    >
      <form
        name="AssistsChangeResponsible"
        class="p-col-12"
        @submit.prevent="saveNewTechnician"
        style="min-height: 15vh"
      >
        <div v-for="pat in pats" :key="pat.pat">
          <p>
            <b>Pat {{ pat.pat }} | {{ pat.fullName }}</b>
          </p>
        </div>
        <div class="p-fluid p-formgrid p-grid" style="min-height: 50vh">
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <span class="p-float-label">
              <Dropdown
                name="responsibleUser"
                :options="listEmployees"
                :filter="true"
                :optionLabel="'username'"
                :optionValue="'employeeId'"
                v-model="current"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('responsibleUser') },
                  'form-control',
                ]"
              >
              </Dropdown>
              <label for="inputtext"
                >Selecionar Técnico Responsável pela Assistência</label
              >
            </span>
            <small
              v-if="errors.has('responsibleUser')"
              class="p-error"
              role="alert"
            >
              Funcionário é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="saveNewTechnician"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import supportService from "../../services/support.service";
import employeeService from "../../services/employee.service";

export default {
  name: "ChangeResponsible",
  props: ["showToast", "show", "currentTechnician", "pats"],
  watch: {
    currentTechnician() {
      this.$validator.pause();
      this.$validator.reset();
      this.current = this.currentTechnician;
    },
  },
  data() {
    return {
      current: this.currentTechnician,
      listEmployees: [],
    };
  },
  created() {
    this.getActiveEmployees();
  },
  methods: {
    getActiveEmployees() {
      return employeeService
        .getSupportActives()
        .then((response) => (this.listEmployees = response));
    },
    cancel() {
      return this.$emit("cancel");
    },
    async saveNewTechnician() {
      this.$validator.validateAll().then(async (isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        let success = false;
        let failure = false;
        for (let pat of this.pats) {
          await supportService
            .changeResponsible(pat.pat, { employeeId: this.current })
            .then((response) => {
              if (!response) {
                if (this.showToast) {
                  failure = true;
                }
                return;
              }
              if (this.showToast) {
                success = true;
              }
              this.$emit("changeResponsibleOk", {
                status: "ok",
                response: response.newEmployeeId,
                newEmployeeUsername: response.newEmployeeUsername,
                office: response.office,
              });
            });
        }

        if (failure) {
          if (this.showToast) {
            this.$toast.add({
              severity: "error",
              summary: `Erro ao alterar técnico responsável`,
              detail: "",
              life: 3000,
            });
          }
          this.$emit("cancel");
        } else if (success) {
          if (this.showToast) {
            this.$toast.add({
              severity: "success",
              summary: `Técnico responsável alterado`,
              detail: "",
              life: 3000,
            });
          }
        }
      });
    },
  },
};
</script>
