<template>
  <div class="p-col-12">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '40vw' }"
      :header="'Marcar Intervenção'"
      :modal="true"
      :closable="false"
    >
      <div class="card">
        <form
          name="MarkInterventionForm"
          class="p-col-12"
          @submit.prevent="markIntervention"
          style="min-height: 20vh"
        >
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-12" v-for="(pat, index) in pats" :key="index">
            <p>
              <b>Pat {{ pat.pat }} | {{ pat.fullName }}</b>
            </p>
            {{ pat.resume }}
          </div>

            <div class="p-field p-col-12 p-md-12 p-mt-2">
              <span class="p-float-label">
                <Dropdown
                  v-model="respTechnical"
                  name="respTechnical"
                  v-validate="'required'"
                  v-bind:class="[
                    { 'p-error': errors.has('respTechnical') },
                    'form-control',
                  ]"
                  :options="technicals"
                  :optionLabel="getUserSearchLabel"
                  :optionValue="'id'"
                  :dataKey="'id'"
                  :filter="true"
                  :required="true"
                >
                  <template #option="slotProps">
                    {{ slotProps.option.username }} ({{
                      slotProps.option.office
                    }})
                  </template>
                </Dropdown>
                <label for="respTechnical">Técnico Responsável</label>
              </span>
              <small
                v-if="errors.has('respTechnical')"
                class="p-error"
                role="alert"
                >Técnico Responsável é obrigatório</small
              >
            </div>
            <div class="p-field p-col-12 p-md-12 p-mt-2">
              <span class="p-float-label">
                <MultiSelect
                  name="moreTechnicals"
                  :options="technicals"
                  :filter="true"
                  :optionLabel="getUserSearchLabel"
                  :optionValue="'id'"
                  :dataKey="'id'"
                  v-model="moreTechnicals"
                  display="chip"
                >
                  <template #option="slotProps">
                    {{ slotProps.option.username }} ({{
                      slotProps.option.office
                    }})
                  </template>
                </MultiSelect>
                <label for="inputtext">Mais Técnicos</label>
              </span>
            </div>

            <div class="p-field p-col-12 p-md-4 p-mt-2">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-calendar"></i>
                </span>
                <span class="p-float-label">
                  <v-date-picker
                    name="day"
                    id="day"
                    v-model="day"
                    v-validate="'required'"
                    :min-date="new Date()"
                    :masks="{
                      input: 'YYYY-MM-DD',
                    }"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <span class="p-float-label">
                        <input
                          v-bind:class="[
                            {
                              'p-error': errors.has('day'),
                            },
                            'form-control',
                            'p-inputtext',
                            'p-component',
                            'p-filled',
                          ]"
                          :value="inputValue"
                          v-on="inputEvents"
                        />
                        <label for="day">Dia</label>
                      </span>
                    </template>
                  </v-date-picker>
                </span>
              </div>
              <small v-if="errors.has('day')" class="p-error" role="alert"
                >Dia é obrigatório</small
              >
            </div>

            <div class="p-field p-col-12 p-md-4 p-mt-2">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-clock"></i>
                </span>
                <span class="p-float-label">
                  <Calendar
                    id="start"
                    ref="start"
                    v-model="start"
                    v-validate="'required|date_format:H:mm'"
                    v-bind:class="[
                      { 'p-error': errors.has('start') },
                      'form-control',
                    ]"
                    :timeOnly="true"
                    :stepMinute="15"
                    name="start"
                  />
                  <label for="inputtext">Hora de início</label>
                </span>
              </div>
              <small v-if="errors.has('start')" class="p-error" role="alert"
                >Hora de início é obrigatório</small
              >
            </div>

            <div class="p-field p-col-12 p-md-4 p-mt-2">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-clock"></i>
                </span>
                <span class="p-float-label">
                  <Calendar
                    id="end"
                    v-model="end"
                    v-validate="'required|date_format:H:mm|after_start'"
                    v-bind:class="[
                      { 'p-error': errors.has('end') },
                      'form-control',
                    ]"
                    :timeOnly="true"
                    :stepMinute="15"
                    name="end"
                  />
                  <label for="inputtext">Hora de fim</label>
                </span>
              </div>
              <small v-if="errors.has('end')" class="p-error" role="alert"
                >Hora de fim tem que ser posterior à de início</small
              >
            </div>
            <div class="p-field p-col-12 p-md-12 p-mt-2">
              <span class="p-float-label">
                <Textarea
                  id="note"
                  name="note"
                  :autoResize="true"
                  v-model="note"
                  v-bind:class="[
                    'p-inputtextarea',
                    'p-inputtext',
                    'p-component',
                  ]"
                  rows="5"
                />
                <label for="inputtext">Nota</label>
              </span>
            </div>
          </div>
        </form>
      </div>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="markIntervention"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import supportService from "../../services/support.service";
import { getOnlyDate, getOnlyTime } from "../../helpers/helpers";
import VeeValidate from "vee-validate";
export default {
  name: "MarkeIntervention",
  props: ["showToast", "show", "pats"],
  watch: {
    patId() {
      this.reset();
    },
  },
  data() {
    return {
      technicals: [],
      moreTechnicals: [],
      patsList: [],
      respTechnical: null,
      day: null,
      start: null,
      end: null,
      note: "",
    };
  },
  beforeMount() {
    Object.keys(this.$store.state.auth.user.supportUsers).forEach((office) => {
      this.technicals = this.technicals.concat(
        this.$store.state.auth.user.supportUsers[office]
      );
    });
  },
  async created() {
    VeeValidate.Validator.extend("after_start", {
      validate: () =>
        new Promise((resolve) => {
          let day = new Date();
          if (!(this.day instanceof Date)) {
            day = new Date(this.day);
          } else {
            day = this.day;
          }

          if (!(this.start instanceof Date)) {
            let newStart = new Date();
            newStart.setSeconds(0);
            newStart.setHours(this.start.split(":")[0]);
            newStart.setMinutes(this.start.split(":")[1]);
            this.start = newStart;
          }
          if (!(this.end instanceof Date)) {
            let newEnd = new Date();
            newEnd.setSeconds(0);
            newEnd.setHours(this.end.split(":")[0]);
            newEnd.setMinutes(this.end.split(":")[1]);
            this.end = newEnd;
          }

          this.start.setFullYear(
            day.getFullYear(),
            day.getMonth(),
            day.getDate()
          );
          this.end.setFullYear(
            day.getFullYear(),
            day.getMonth(),
            day.getDate()
          );
          resolve({
            valid: this.end > this.start,
          });
        }),
    });
  },
  methods: {
    reset() {
      this.moreTechnicals = [];
      this.respTechnical = null;
      this.day = null;
      this.start = null;
      this.end = null;
      this.note = "";
      this.note = "";
    },
    getUserSearchLabel(info) {
      return `${info.username} (${info.office})`;
    },
    cancel() {
      this.reset();
      this.$validator.pause();
      this.$validator.reset();
      return this.$emit("cancel");
    },
    async markIntervention() {
      let failure = false;
      let success = false;
      let successStatus = "";
      this.$validator.validateAll().then(async (isValid) => {
        if (!isValid || !this.day) {
          return;
        }
        let bodyParams = {
          day: getOnlyDate(this.day),
          start: getOnlyTime(this.start),
          end: getOnlyTime(this.end),
          respTechnical: this.respTechnical,
          note: this.note,
        };
        bodyParams.moreTechnicals = this.moreTechnicals.filter(
          (tec) => tec != this.respTechnical
        );
        this.$validator.pause();
        this.$validator.reset();
        for (let pat of this.pats) {
          await supportService
            .markIntervention(parseInt(pat.pat), bodyParams)
            .then((response) => {
              if (!response) {
                failure = true;
              } else {
                success = true;
                successStatus = response.status;
              }
            })
          }
          if (failure) {
            if (this.showToast) {
              this.$toast.add({
                severity: "error",
                summary: "Erro ao marcar intervenção",
                detail: "",
                life: 3000,
              });
            }
            return this.$emit("marked", {
              status: "error",
              currentStatus: null,
            });
          } else if (success) {
            if (this.showToast) {
              this.$toast.add({
                severity: "success",
                summary: `Intervenções marcadas com sucesso`,
                detail: ``,
                life: 3000,
              });
            }
            this.$emit("marked", {
              status: "ok",
              currentStatus: successStatus,
            });
          }
        });
      }
    },
  }

</script>
