<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :closable="false"
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Alterar Data do PAT de Assistência'"
      :modal="true"
      :contentStyle="{ overflow: 'hide' }"
    >
    <div v-if="pats && pats.length > 0">
      <div v-for="(pat, index) in pats" :key="index">
        <b>Pat: {{ pat.pat }} - </b> {{ pat.patName }} |
      </div>
      <div>
        <form
          name="setDateForm"
          class="p-col-12"
          @submit.prevent="patSetDate()"
          style="min-height: 15vh"
        >
          <div class="p-d-flex p-jc-center">
            <v-date-picker
              v-model="pat.date"
              :min-date="new Date()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
            </v-date-picker>
          </div>
        </form>
      </div>
    </div>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="patSetDate()"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import supportService from "../../services/support.service";
import { getOnlyDate } from "../../helpers/helpers";
export default {
  name: "AssistChangeDate",
  props: ["pats", "showToast", "show"],
  data() {
    return {
      date: this.pats && this.pats.length > 0 && this.pats[0].date ? new Date(this.pats[0].date) : new Date(),
    };
  },
  methods: {
    cancel() {
      return this.$emit("cancel");
    },
    async patSetDate() {
      let bodyParams = {}
      for (let pat of this.pats) {
        let bodyParams = {
          date: getOnlyDate(pat.date),
        };

        await supportService.setDate(pat.patId, bodyParams).then((response) => {
          if (!response) {
            if (this.showToast) {
              this.$toast.add({
                severity: "error",
                summary: "Erro ao alterar a data",
                detail: "Ocorreu um erro ao alterar a data do PAT",
                life: 3000,
              });
            }
            this.$emit("setDateOk", {
              status: "error",
              currentDate: pat.patDate,
            });
            return;
          }

          if (this.showToast) {
            this.$toast.add({
              severity: "success",
              summary: "Data alterada",
              detail: "A data do PAT foi alterada com sucesso",
              life: 3000,
            });
          }
        });
      }

      this.$emit("setDateOk", {
        status: "ok",
        currentDate: bodyParams.date,
      });
    },
  },
};
</script>
