<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Colocar Assistências como Pendente'"
      :modal="true"
      :closable="false"
    >
      <form
        name="changeTopending"
        class="p-col-12"
        @submit.prevent="setPendingStatus"
        style="min-height: 15vh"
      >
        <div class="p-fluid p-formgrid p-grid" style="min-height: 20vh">
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <p><b>Pats:</b></p>
            <ul>
              <li v-for="pat in pats" :key="pat.pat">
                <b>Pat:</b> {{ pat.pat }} - <b>Nome:</b> {{ pat.fullName }}
              </li>
            </ul>
            <br>
            <span class="p-float-label">
              <Textarea
                name="comment"
                :autoResize="true"
                v-model="comment"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-error': errors.has('comment') },
                  'p-inputtextarea',
                  'p-inputtext',
                  'p-component',
                ]"
                rows="5"
              />
              <label for="comment">Comentário</label>
            </span>
            <small v-if="errors.has('comment')" class="p-error" role="alert">
              Comentário é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="setPendingStatus"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import supportService from "../../services/support.service";

export default {
  name: "SetPending",
  props: ["showToast", "show", "pats"],
  data() {
    return {
      comment: null,
    };
  },
  methods: {
    cancel() {
      this.comment = null;
      return this.$emit("cancel");
    },
    async setPendingStatus() {
      let lastComment = '';
      const isValid = this.$validator.validateAll();
      if (!isValid) {
        return;
      }
      for (let pat of this.pats) {
        await supportService
          .setPending(parseInt(pat.pat), { status: this.selectedStatus, message: this.comment })
          .then((response) => {
            if (!response) {
              if (this.showToast) {
                this.$toast.add({
                  severity: "error",
                  summary: `Erro ao colocar assistências como pendente`,
                  detail: "",
                  life: 3000,
                });
              }
            }
            if (this.showToast) {
              this.$toast.add({
                severity: "success",
                summary: `As assistências mudaram para pendente`,
                detail: "",
                life: 3000,
              });
            }
            lastComment = response.message;
          });
        }
        this.$emit("setPendingOk", {
          status: "ok",
          currentStatus: "Pendente",
          lastComment: lastComment,
        });
    },
  }
};
</script>
